import axios from 'axios';
import React from 'react';

import Popup, { ClosePopup, PopupActions, PopupContent, PopupHeader } from 'Components/Popup';
import { EntrarButton } from 'Views/Login/Form';

const AppMigrationPopup = ({ close, user }) => {
	if (!user || !user.beneficiary) {
		close();
		return null;
	}

	return (
		<Popup>
			<PopupHeader>
				<h4>Agora a AVUS está disponível no IOS e Android</h4>
				<ClosePopup onClick={close} />
			</PopupHeader>
			<PopupContent>
				<div style={{ padding: '1rem', fontSize: 'medium', textAlign: 'center' }}>
					Para continuar navegando e utilizando seus benefícios, baixe o aplicativo e faça seu login.
				</div>
			</PopupContent>
			<PopupActions>
				<EntrarButton type="submit">
					<a href="https://lp.avus.com.br/app" target="_blank" style={{ color: 'inherit', textDecoration: 'none' }}>
						Baixar aplicativo
					</a>
				</EntrarButton>
			</PopupActions>
		</Popup>
	);
};

export const checkMigration = async ({ user }) => {
	if (!user || !user.beneficiary || !user.beneficiary.contract) return false;
	console.log({ user });

	const { data: response } = await axios.get(`/api/v2/migrationContracts`);

	return Array.isArray(response) && response.includes(user.beneficiary.contract.code);
};

export default AppMigrationPopup;
